import React from "react";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
// import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
// import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    border: "1px solid #ced4da",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    padding: theme.spacing(1, 2),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
      // borderColor: theme.palette.primary.main
    }
  },
  error: {
    borderRadius: 2,
    border: `1px solid ${theme.palette.error.main}`,
    "&:focus": {
      boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`
    }
  }
}))(InputBase);

const BootstrapLabel = withStyles(theme => ({
  root: {
    fontWeight: 700,
    fontSize: "1.01rem",
    padding: theme.spacing(1, 0)
  }
}))(InputLabel);

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1)
  }
}));

const FormikTextField = ({
  formik,
  name,
  autoComplete,
  label,
  variant,
  helper,
  type
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isInError = !!formik.touched[name] && !!formik.errors[name];
  return (
    <div className={classes.root}>
      {label && (
        <BootstrapLabel htmlFor={name}>
          {t(`forms.${label}.label`)}
        </BootstrapLabel>
      )}
      <BootstrapInput
        id={name}
        name={name}
        error={isInError}
        fullWidth
        autoComplete={autoComplete}
        placeholder={t(`forms.${label}.label`)}
        type={type || "text"}
        multiline={variant === "multiline"}
        rows={variant === "multiline" ? 9 : null}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
      />
      {isInError ? (
        <FormHelperText htmlFor={name} id={`label-${name}`}>
          {t(`forms.${name}.${formik.errors[name]}`)}
        </FormHelperText>
      ) : null}
      {helper ? (
        <FormHelperText htmlFor={name} id={`label-${name}`}>
          {t(`forms.${label}.helper`)}
        </FormHelperText>
      ) : null}
    </div>
  );
};
export default FormikTextField;

/*
  <BootstrapInput
    id={name}
    name={name}
    error={isInError}
    variant="outlined"
    fullWidth
    autoComplete={autoComplete}
    label={label}
    type={type || "text"}
    multiline={variant === "multiline"}
    rows={variant === "multiline" ? 9 : null}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values[name]}
  />
  */
