import React from "react";
import firebase from "firebase/app";
import { useDocumentData } from "react-firebase-hooks/firestore";
import AdminRouter from "../routers/AdminRouter";
import ViewerRouter from "../routers/ViewerRouter";
import OnboardUser from "../components/onboarding/OnboardUser";
import Disclaimer from "../components/core/Disclaimer";
import UserContext from '../context/UserContext'

/**
 * Load the user and select an appropriate router
 */
function UserBoundary() {
  const { uid } = firebase.auth().currentUser;

  const query = firebase
    .firestore()
    .collection("users")
    .doc(uid);

  const [user, userLoading, userError] = useDocumentData(query);

  if (userLoading) return null;

  // if user doesn't exist show Onboard User
  if (userError) return <OnboardUser />;

  // user exists so get the type
  const { type, acceptedDisclaimer } = user;

  // If user is an admin but isn't verified

  if (!acceptedDisclaimer) return <Disclaimer />;

  switch (type) {
    case "admin":
      return (
        <UserContext.Provider value={user}>
          <AdminRouter />
        </UserContext.Provider>
      );
    default:
      return (
        <UserContext.Provider value={user}>
          <ViewerRouter />
        </UserContext.Provider>
      );
  }
}

export default UserBoundary;
