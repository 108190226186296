import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: "#FFF"
  }
}));

export default function FeatureHeader({ children }) {
  const classes = useStyles();
  return (
    <Typography variant="h1" className={classes.root}>
      {children}
    </Typography>
  );
}
