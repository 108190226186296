import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./utils/sentry";
import "./utils/firebase";
import "./utils/i18n";
import "./utils/version";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./worker/serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
// ReactDOM.render(<Fonts />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
