import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "../../core/icons/GlobalConsent";
import { motion } from "framer-motion";

const variants = {
  hidden: {
    x: "-100%"
  },
  visible: {
    x: "0"
  }
};
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    padding: theme.spacing(3),
    // backgroundImage: ,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.background.dark,
    //   theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  icon: {
    padding: theme.spacing(5)
  }
}));

export default function OnboardSidebar({ children }) {
  const classes = useStyles();

  return (
    <Grid item sm={false} md={4} className={classes.root}>
      <div className={classes.icon}>
        <Icon height="80px" />
      </div>
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <div>{children}</div>
      </motion.div>
      <div>&nbsp;</div>
    </Grid>
  );
}
