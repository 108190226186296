import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },

  item: {
    margin: "20px"
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // @TODO work out if necessary?
  // componentDidCatch(error, errorInfo) {
  // You can also log the error to an error reporting service
  // logErrorToMyService(error, errorInfo);
  // }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.root}>
          <div className={classes.item}>
            <Typography variant="h1">Something went wrong.</Typography>
          </div>
          <div className={classes.item}>
            <Button variant="contained" component="a" href="/">
              Reload
            </Button>
          </div>
          <div className={classes.item}>
            <Button
              variant="contained"
              component="a"
              href="https://globalconsent.com/contact"
            >
              Contact Support
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
