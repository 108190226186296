import React, { useEffect } from "react";
import firebase from "firebase/app";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import OnboardView from "./core/OnboardView";
import OnboardSidebar from "./core/OnboardSidebar";
import OnboardUserForm from "./OnboardUserForm";
import AccountIcon from "@material-ui/icons/AccountBox";
import FeatureHeader from "./core/FeatureHeader";
import FeatureSubtitle from "./core/FeatureSubtitle";
import FeatureList from "./core/FeatureList";
import FeatureListItem from "./core/FeatureListItem";
import OnboardingCore from "./core/OnboardCore";
import { motion } from "framer-motion";
import { variants } from "../../utils/framer";

export default function Onboarduser() {
  useEffect(() => {
    firebase.analytics().logEvent("onboard_user");
  }, []);

  return (
    <OnboardView>
      <OnboardSidebar>
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <FeatureHeader>Your account has been created</FeatureHeader>
        </motion.div>
        <FeatureSubtitle>
          We just need a little more information so we can provide you the best
          onboarding experience
        </FeatureSubtitle>
        <FeatureList>
          <FeatureListItem>
            <strong>About You</strong>
          </FeatureListItem>
          <FeatureListItem>Your Account Details</FeatureListItem>
        </FeatureList>
      </OnboardSidebar>

      <OnboardingCore>
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <Toolbar>
            <Typography variant="h1">
              <AccountIcon /> About You
            </Typography>
          </Toolbar>
          <OnboardUserForm />
        </motion.div>
      </OnboardingCore>
    </OnboardView>
  );
}
