import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import RawDivider from "@material-ui/core/Divider";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Transition from "../../utils/Transition";
// import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import moment from "moment";
import { useAsyncCallback } from "react-async-hook";

const Divider = withStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0)
    // boxShadow: "none",
    // textTransform: "none",
    // fontSize: 16,
    // padding: "6px 12px",
    // lineHeight: 1.5,
    // backgroundImage: "linear-gradient(-180deg,#34d058,#28a745 90%)",
    // border: "1px solid rgba(17,11,15,.2)",
    // backgroundColor: "#007bff",
    // borderColor: "#007bff",
    // borderRadius: "3px",
    // color: "#FFF",
  }
}))(RawDivider);

export default function AlertDialog() {
  // const [open, setOpen] = React.useState(true);
  // const history = useHistory();

  const asyncOnClick = useAsyncCallback(async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .update({
        acceptedDisclaimer: moment().toISOString()
      });
  });

  const handleAway = () => {};

  return (
    <Dialog
      open={true}
      onClose={handleAway}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        Global Consent Disclaimer
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Typography variant="subtitle2">
          This site does not obtain fully informed consent, informed consent or
          consent
        </Typography>
        <Divider />
        <Typography variant="caption">Last updated: 20th March 2020</Typography>
        <Divider />
        <Typography variant="h4">No Medical Advice</Typography>
        <Typography variant="body1">
          Global Consent does not provide medical advice. Global Consent
          provides medical information prior to planned medical or surgical
          treatment. The content is not intended to be a substitute for
          professional advice, diagnosis or treatment. Always seek the advice of
          your physician or qualified healthcare provider. Global consent does
          not establish a doctor patient relationship with users of the site.{" "}
        </Typography>
        <Typography variant="body1">
          The contents of the Global Consent Website, such as text, graphics,
          images, videos and other materials created by Global Consent or
          obtained from Global Consent’s licensors and other materials created
          by Global Consent are for informational purposes only (collectively
          “content”).{" "}
        </Typography>
        <Divider />
        <Typography variant="h4">No Consent</Typography>
        <Typography variant="body1">
          The content is not a substitute for fully informed consent or informed
          consent (collectively “consent”). Global Consent is not delegated to
          obtain consent on this website. Global Consent will not accept any
          liability for failure to warn or consent related issues.{" "}
        </Typography>
        <Divider />
        <Typography variant="h4">No Warranties</Typography>
        <Typography variant="body1">
          The information is provided without any warranties, whether expressed
          or implied. Global Consent Ltd has made every attempt to ensure the
          accuracy and reliability of the information provided on this website.
          To the best of our knowledge, the information is true and complete.
          However, the information is provided “as is” without warranty of any
          kind. Global consent does not accept any responsibility for the
          accuracy, content, completeness, legality or reliability of the
          information on this site. Global Consent expressly disclaims liability
          for errors and omissions in the contents of this site.{" "}
        </Typography>
        <Typography variant="body1">
          Global consent makes every effort to keep the site up and running
          smoothly. However, Global consent takes no responsibility for and will
          not be liable for the site being temporarily unavailable due to
          technical issues beyond our control.
        </Typography>
        <Typography variant="body1">
          Global Consent does not recommend or endorse any specific tests,
          physicians, products, opinions or other information that may be
          mentioned on the Site. Reliance on any information provided by Global
          Consent, Global Consent employees, Global Consent affiliates, others
          appearing on the site at the invitation of Global Consent or other
          visitors to the Site, is solely at your own risk.
        </Typography>
        <Typography variant="body1">
          Global consent accepts no responsibility for issues raised by patient
          or users following visits to the site, which may cause queries,
          delays, cancellations of treatment(s) or any other unplanned event.
        </Typography>
        <Divider />
        <Typography variant="h4">External Links Disclaimer</Typography>
        <Typography variant="body1">
          We cannot and will not guarantee that this website is free from
          computer viruses or anything else that has destructive properties.
          This website provides links to other websites owned by third parties.
          The content of such third-party sites is not within our control and we
          cannot and will not take responsibility for the information or content
          thereon.
        </Typography>
        <Typography variant="body1">
          Links to such third-party sites are not to be taken as an endorsement
          by Global Consent of the third-party site or any products promoted,
          offered or sold on the third-party site not that such sites are free
          from computer viruses or anything else that has destructive
          properties. We cannot and do not take responsibility for the
          collection or use of personal data from any third-party site.
        </Typography>
        <Divider />
        <Typography variant="h4">
          Exclusions to Limitation of Liability
        </Typography>
        <Typography variant="body1">
          Nothing in this statement will limit any liability for death and
          personal injury caused by negligence, fraud or fraudulent
          misrepresentation or any liabilities that cannot be permitted or
          excluded under applicable law.
        </Typography>
        <Divider />
        <Typography variant="h4">Contact Details</Typography>
        <Typography variant="body1">
          If you have any questions please contact us by email
          info@globalconsent.com or write to us at the following address: Global
          Consent Ltd, Yew Tree House, Stratford Road, Wootton Wawen,
          Warwickshire, B95 6AR
        </Typography>
        <Divider />
        <Typography variant="h4">Definitions</Typography>
        <Typography variant="body1">
          The following terms are used throughout:
        </Typography>
        <ul>
          <li>
            The Global Consent website at the following address:
            www.globalconsent.com is referred to as “Website”, “site”
          </li>
          <li>
            The term patients or users refers to people registered on the site
            by professionals, for the purposes of using our services.
          </li>
          <li>
            The term services(s) refers to our products which include but are
            not limited to the provision of education in medical conditions,
            surgical treatments, procedures, diseases and conditions and the
            provision of audit data following medical or surgical intervention.
          </li>
          <li>
            The term subscriber(s) refers to a professional who has registered
            on our site for the purposes of engaging our services for their
            patients or clients and for whom we provide contracted services.
          </li>
          <li>
            The term “professional(s)” or “your professional(s)” refers to a
            subscriber such as a doctor or their team, a clinic, a company, the
            NHS including administrative staff, who register patients or clients
            on our site for the purposes of contracting our services
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          underline="none"
          href="https://www.globalconsent.com"
          color="secondary"
        >
          Take me Back
        </Button>
        <Button
          onClick={asyncOnClick.execute}
          color="primary"
          variant="contained"
        >
          Accept Medical Disclaimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
