import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  root: {
    "@media print": {
      padding: 0
    },
    flexGrow: 1,
    paddingTop: theme.mixins.toolbar.minHeight,
    paddingBottom: theme.mixins.toolbar.minHeight
  },
  container: {
    padding: theme.spacing(4, 2)
  }
}));

export default function MainView({ className, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={clsx(classes.container, className)}>
        {children}
      </Container>
    </div>
  );
}
