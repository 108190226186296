import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  shadow: {
    "-webkit-filter": "drop-shadow( 1px 1px 0.5px rgba(0, 0, 0, .4))",
    filter: "drop-shadow( 1px 1px 0.5px rgba(0, 0, 0, .4))"
  }
}));

/**
 * Global Consent Icon with multiple variants
 * @param {*} props
 */
function GlobalConsentIcon(props) {
  const theme = useTheme();
  const classes = useStyles();
  const variant = "white";

  return (
    <svg
      className={classes.shadow}
      id="Layer_1"
      data-name="Layer 1"
      width={props.width || "50px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2018.701 1867.734"
    >
      <title>Global Consent Logo</title>
      <path
        d="M1104.789,96.559q259.65,44.213,459.146,215.6a914.787,914.787,0,0,1,156.4,173.828c52.394,75.468,36.815,177.7-34.645,228.889-76.07,54.488-174.561,38.527-231.824-37.439-99.549-132.062-230.808-214.843-393.835-240.344C724.32,384.576,429.006,602.944,363.64,915.76c-56.935,272.477,78.289,547.175,325.917,671.231,221.863,111.148,488.208,76.187,675.84-88.069,31.875-27.9,59.34-59.5,85.942-92.176,54.672-67.16,149.1-79.586,213.006-28.506,70.029,55.975,81.143,147.253,25.805,218.463a923.044,923.044,0,0,1-233.912,213.091c-174.265,111.223-365.52,160.92-570.782,145.249-405.584-30.965-729.592-300.285-839.3-691.7-13.195-47.078-20.663-95.24-26.831-143.638-.545-4.283.673-9.26-4.676-11.6V936.586c3.949-2.021,4.172-5.745,4.514-9.527,4.128-45.624,10.758-91.026,23.392-134.963,107.875-375.169,351.573-606.61,733.563-691.509C866.43,83.048,1077.036,91.834,1104.789,96.559Z"
        transform="translate(-14.65 -90.133)"
        fill={props.variant === variant ? "rgba(255,255,255,0.6)" : "#a8adaf"}
      />
      <path
        d="M968.071,1554.556c-258.8.411-475.116-184.961-516.253-442-45.424-283.816,150.563-553.088,437.05-596.152,184.25-27.7,340.773,30.058,467.936,165.928,45.945,49.091,42.208,129.321-6.211,174.8-50.838,47.749-131.541,47.395-178.762-2.784-39.24-41.7-84.956-71.618-140.679-84.6-177.937-41.44-342.5,96.371-331.191,279.6,6.76,109.52,64.208,188.208,164.62,231,99.039,42.212,192.939,26.616,276.4-41.4,30.718-25.037,59.753-49.995,102.445-50.13,52.317-.166,98.668,27.648,118.545,74.627,21.383,50.54,14.786,98.958-23.307,137.907-81.36,83.189-181.205,132.022-296.451,148.947-12.617,1.853-25.329,3.368-38.051,4.1C992.165,1555.1,980.1,1554.556,968.071,1554.556Z"
        transform="translate(-14.65 -90.133)"
        fill={
          props.variant === variant
            ? "rgba(255,255,255,0.8)"
            : theme.palette.primary.main
        }
      />
      <path
        d="M1696.506,1217.03c-57.137,0-114.276.238-171.412-.056-84.632-.435-158.38-67.156-164.976-148.84-7.474-92.563,49.98-170.527,136.837-186.294a222.448,222.448,0,0,1,40.271-3.639c109.013.125,218.026-.137,327.037.145,68.937.179,132.326,43.327,156.551,105.677,26.167,67.349,10.8,140.521-40.746,188.175-31.409,29.039-68.478,45.225-112.15,44.927C1810.783,1216.736,1753.644,1217.03,1696.506,1217.03Z"
        transform="translate(-14.65 -90.133)"
        fill={props.variant === variant ? "rgba(255,255,255,0.6)" : "#a8adaf"}
      />
    </svg>
  );
}

export default GlobalConsentIcon;
