import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  }
}));

export default function OnboardingView({ children }) {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      {children}
    </Grid>
  );
}
