import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import firebase from "firebase/app";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikName from "../core/forms/FormikName";
import FormikToggle from "../core/forms/FormikToggle";
import Alert from "../core/Alert";
import UserIcon from "@material-ui/icons/Person";
import TypeIcon from "@material-ui/icons/Category";
// import Loading from "../core/LoadingFullscreen";
import { variants } from "../../utils/framer";
import { motion } from "framer-motion";
import User from "../../models/User";

const schema = Yup.object().shape({
  title: Yup.string("string").required("required"),
  first: Yup.string("string").required("required"),
  last: Yup.string("string").required("required"),
  medicalReference: Yup.string("string"),
  role: Yup.string("string").required("required")
});

export default function OnboardProfile() {
  const formik = useFormik({
    initialValues: {
      title: "",
      first: "",
      role: "",
      medicalReference: "",
      last: ""
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      const uid = firebase.auth().currentUser.uid;
      // Load values into the user object
      const userModel = new User();
      userModel
        .init({
          type: values.role,
          roles: {
            [uid]: "owner"
          },
          title: values.title,
          first: values.first,
          last: values.last
        })
        .then(() => {
          // create the user's record
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .set(userModel.data())
            .then(result => {
              // update the analytics
              firebase.auth().currentUser.sendEmailVerification();
              firebase.analytics().logEvent("onboarding_profile_set");
              // send the verification email
              // reload the page
              //@todo make better
              window.location.assign("/welcome/billing");
            })
            .catch(error => {
              setFieldError("general", error.message);
            });
        })
        .catch(error => {
          setFieldError("general", error.message);
        });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      {formik.errors && formik.errors.general && (
        <Alert>{formik.errors.general}</Alert>
      )}
      <Toolbar>
        <UserIcon />
        <Typography variant="h2">Please tell us your name</Typography>
      </Toolbar>
      <FormikName formik={formik} autoComplete="off" />
      <Toolbar>
        <TypeIcon />
        <Typography variant="h2">Which describes you best?</Typography>
      </Toolbar>
      <FormikToggle
        formik={formik}
        name="role"
        label="role"
        options={["admin", "viewer", "manager"]}
      />
      <motion.div
        initial="hidden"
        animate={
          formik.values.role && formik.values.role !== "admin"
            ? "visible"
            : "hidden"
        }
        variants={variants}
      >
        <Alert>
          Ahh. Sorry, at this time we're only accepting sign up by Doctors.
        </Alert>
      </motion.div>

      <Toolbar flex="end">
        <Button
          type="submit"
          disabled={formik.values.role !== "admin" || formik.isSubmitting}
          variant="contained"
          color="primary"
        >
          Next &#x2192;
        </Button>
      </Toolbar>
    </form>
  );
}

/*

        <Loading open={formik.isSubmitting} message="Setting you up!" />
      {formik.values.role === "admin" && (
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <Toolbar>
            <Bookmark />
            <Typography variant="h2">
              Your professional medical reference number
            </Typography>
          </Toolbar>
          <FormikTextField
            formik={formik}
            name="medicalReference"
            label="medicalReference"
            helper="Eg. your GMC number in the UK"
            autoComplete={"off"}
          />
        </motion.div>
      )}
      */
