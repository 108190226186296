import React from "react";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "../components/core/Loading";
import PublicRouter from "../routers/PublicRouter";

/**
 * Authentication Boundary chooses between logged in and logged out user
 * @param {*} props
 */
function AuthBoundary(props) {
  const [user, initialising, error] = useAuthState(firebase.auth());

  if (initialising) {
    return <Loading message="authenticating" />;
  }
  if (error) throw Error("Authentication Error");

  if (!user) {
    return <PublicRouter />;
  }

  return props.children;
}

export default AuthBoundary;
