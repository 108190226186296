import * as yup from "yup";
import GCDoc from "./GCDoc";

export default class User extends GCDoc {
  /**
   * A class for managing a user's profile
   * @constructs user
   * @extends GCDoc
   */
  constructor() {
    super();
    this.inputSchema = yup.object().shape({
      id: yup.string("string"),
      hospitalNumber: yup.string("string"),
      profile: yup.string("bool"),
      title: yup.string("string").required("required"),
      first: yup.string("string").required("required"),
      middle: yup.string("string"),
      last: yup.string("string").required("required"),
      suffix: yup.string("string"),
      dob: yup.date("date"),
      acceptedDisclaimer: yup.date("date"),
      isActive: yup.bool("bool"),
      type: yup.string("string").required("required"),
      roles: yup.object().required("required"),
      created: yup.date("required")
    });

    this.types = {
      viewer: "viewer",
      admin: "admin",
      manager: "manager"
    };

    this.mData = Object.assign(this.mData, {
      hospitalNumber: "",
      title: "",
      first: "",
      last: "",
      dob: "",
      profile: false,
      lang: "en",
      acceptedDisclaimer: false,
      isActive: false,
      type: this.types.viewer
    });
  }
}
