import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useTranslation } from "react-i18next";
import DashboardIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/PeopleAlt";
import SettingsIcon from "@material-ui/icons/Settings";
import VideoIcon from "@material-ui/icons/VideoLibrary";

import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "@media print": {
      display: "none"
    },
    boxShadow: "0 0 3px rgba(0,0,0,0.5)",
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 1000
    // borderTop: '1px solid #AAA'
  }
});

export default function MobileTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        component={NavLink}
        to="/"
        label={t("dashboard.title_short")}
        icon={<DashboardIcon />}
      />
      <BottomNavigationAction
        component={NavLink}
        to="/directory"
        label="Patients"
        icon={<PeopleIcon />}
      />
      <BottomNavigationAction
        component={NavLink}
        to="/browse"
        label={t("modules.title")}
        icon={<VideoIcon />}
      />
      <BottomNavigationAction
        component={NavLink}
        to="/settings"
        label={t("settings.title")}
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
}
