import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "./Avatar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// import Badge from "@material-ui/core/Badge";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import CreateIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import ExternalLink from "@material-ui/core/Link";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(2)
  },
  appBar: {
    "@media print": {
      display: "none"
    },
    color: theme.palette.text.primary,
    background: theme.palette.background.light,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: {
    paddingLeft: theme.spacing(2)
  },
  feedback: {
    padding: theme.spacing(0.5, 2),
    opacity: 0.8,
    fontSize: "0.9rem"
  },
  search: {
    flexGrow: 1,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.95),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.65)
    },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    marginLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "auto",
      minWidth: "400px"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
}));

export default function MainAppBar({ onOpenDialog }) {

  const classes = useStyles();
  // const { t } = useTranslation();
  // const notifications = 0;


  return (
    <AppBar position="fixed" elevation={6} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search for something…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <Hidden smDown implementation="css">
          <Button
            variant="contained"
            component={ExternalLink}
            underline="none"
            target="_blank"
            href="https://www.globalconsent.com/contact"
            color="default"
            className={classes.feedback}
          >
            Give us Feedback
          </Button>
        </Hidden>
          <Avatar/>

        <Hidden mdUp implementation="css">
          <IconButton
            onClick={() => {
              onOpenDialog("create-viewer");
            }}
          >
            <CreateIcon />
          </IconButton>
        </Hidden>

      </Toolbar>
    </AppBar>
  );
}

/*
        <IconButton
          aria-label={`show ${notifications} new notifications`}
          color="inherit"
        >
          <Badge badgeContent={notifications} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        */
