import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typo from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1),
    textAlign: "center",
    fontSize: "1.02rem",
    fontWeight: 300,
    color: "#FFF"
  }
}));

export default function FeatureSubtitle({ children }) {
  const classes = useStyles();
  return (
    <Typo variant="h2" className={classes.root}>
      {children}
    </Typo>
  );
}
