import React from "react";
import FormikTextField from "./FormikTextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const FormikName = ({ formik, autoComplete }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={3}>
        <FormikTextField
          formik={formik}
          name="title"
          label="title"
          autoComplete={autoComplete || "honorific-prefix"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          formik={formik}
          name="first"
          label="first"
          autoComplete={autoComplete || "given-name"}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormikTextField
          formik={formik}
          name="last"
          label="last"
          helper="last"
          autoComplete={autoComplete || "family-name"}
        />
      </Grid>
    </Grid>
  );
};

export default FormikName;
