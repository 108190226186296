import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  paper: {
    height: "100vh",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center"
  }
}));

export default function SignInSide({ children }) {
  const classes = useStyles();
  return (
    <Grid item sm={12} md={8} elevation={6}>
      <Container maxWidth={"sm"} className={classes.paper}>
        {children}
      </Container>
    </Grid>
  );
}
