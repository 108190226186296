import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Loading from "./components/core/Loading";
import ErrorBoundary from "./boundaries/ErrorBoundary";
import AuthBoundary from "./boundaries/AuthBoundary";
import UserBoundary from "./boundaries/UserBoundary";
import theme from "./utils/theme";

/**
 *
 * Main App providers and routers
 */
function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Suspense fallback={<Loading message="loading profile" />}>
            <ErrorBoundary>
              <AuthBoundary>
                <UserBoundary />
              </AuthBoundary>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
