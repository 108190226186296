import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import MainView from "../components/core/interfaces/MainView";
import AppBar from "../components/core/interfaces/ViewerAppBar";

const Error404 = lazy(() => import("../components/errors/Error404"));
const Settings = lazy(() => import("../components/settings/account/Account"));
const Browse = lazy(() => import("../components/browse/Browse.js"));
const Module = lazy(() => import("../components/module/Module"));

/**
 * Client side router for logged in users. Also has navbar here.
 * @param {*} props
 */
function AuthenticatedRouter(props) {
  return (
    <>
      <AppBar />
      <MainView>
        <Switch>
          <Route
            exact
            path="/module/:userId/:courseId/:moduleId"
            component={Module}
          />
          <Route exact path="/welcome/specialty" component={Browse} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/" component={Browse} />

          <Route render={props => <Error404 {...props} />} />
        </Switch>
      </MainView>
    </>
  );
}

export default AuthenticatedRouter;
