import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  }
}));

export default function Alert({ children, variant }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, { [classes.info]: variant === "info" })}>
      {children}
    </div>
  );
}
