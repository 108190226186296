import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const FormikToggle = ({ formik, name, options, label }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isInError = !!formik.touched[name] && !!formik.errors[name];
  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <RadioGroup
          aria-label="position"
          name="position"
          value={formik.values[name]}
          onChange={formik.handleChange}
          row
        >
          {options.map(o => (
            <FormControlLabel
              value={o}
              key={o}
              name={name}
              control={<Radio color="primary" />}
              label={t(`forms.${label}.${o}`)}
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </FormControl>
      {isInError ? (
        <FormHelperText htmlFor={name} id={`label-${name}`} color="red">
          {t(`forms.${label}.${formik.errors[name]}`)}
        </FormHelperText>
      ) : null}
    </div>
  );
};
export default FormikToggle;

/*
        <TextField
          id={name}
          name={name}
          error={isInError}
          variant="outlined"
          fullWidth
          autoComplete={autoComplete}
          label={t(`forms.${label}.label`)}
          type={type || "text"}
          multiline={variant === "multiline"}
          rows={variant === "multiline" ? 5 : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
        */
