import moment from "moment";

export default class GCDoc {
  /**
   * A parent class for Global Consent documents
   * @constructs GCDoc
   */
  constructor() {
    this.mData = {
      roles: {},
      created: moment().toISOString(),
      v: 1
    };
  }

  generateReverseTimestamp() {
    this.max = moment("2300-01-01").unix();
    this.now = moment().unix();
    return `${this.max - this.now}`;
  }

  async init(config) {
    await this.inputSchema.validate(config);
    this.mData = Object.assign(this.mData, config);
  }

  data() {
    return this.mData;
  }
}
