import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import PlusIcon from "@material-ui/icons/AddCircle";
import PeopleIcon from "@material-ui/icons/PeopleAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoIcon from "@material-ui/icons/VideoLibrary";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import Fab from "@material-ui/core/Fab";
import { Link, NavLink } from "react-router-dom";
import LinkA from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GlobalConsent from "../icons/GlobalConsent";

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawer: {
    width: drawerWidth,
    boxShadow: theme.shadows[12]
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    textDecoration: "none"
  },
  icon: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  },
  plusIcon: {
    color: theme.palette.secondary.main
  },
  addPatient: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2),
    background: "#FFF"
  },
  listItem: {
    color: theme.palette.text.primary,
    transition: "all 0.5s ease"
  },
  listItemActive: {
    color: theme.palette.primary.main,
    background: "#fafafb"
  }
}));

export default function AdminDrawer({ onOpenDialog }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <nav className={classes.root} aria-label="menu">
      <Drawer
        classes={{
          paper: classes.drawer
        }}
        variant="permanent"
        open
      >
        <div className={classes.icon}>
          <GlobalConsent width={60} variant="medical" />
          <div>
            <Typography
              component={Link}
              to="/"
              underline="none"
              variant="subtitle1"
              className={classes.title}
            >
              {t("site.title")}
            </Typography>
          </div>
        </div>
        <Fab
          underline="none"
          onClick={() => {
            onOpenDialog("create-viewer");
          }}
          variant="extended"
          className={classes.addPatient}
        >
          <PlusIcon className={classes.plusIcon} />
          {t("createpatient.button")}
        </Fab>
        <List>
          {[
            {
              title: t("dashboard.title"),
              exact: true,
              icon: <DashboardIcon />,
              url: "/"
            },
            {
              title: t("patients.title"),
              exact: false,
              icon: <PeopleIcon />,
              url: "/directory"
            },
            {
              title: t("modules.title"),
              exact: true,
              icon: <VideoIcon />,
              url: "/browse"
            },
            {
              title: t("settings.title"),
              exact: false,
              icon: <SettingsIcon />,
              url: "/settings"
            }
          ].map(text => (
            <ListItem
              button
              key={text.title}
              underline="none"
              exact={text.exact}
              className={classes.listItem}
              component={NavLink}
              to={text.url}
              activeClassName={classes.listItemActive}
            >
              <ListItemIcon>{text.icon}</ListItemIcon>
              <ListItemText primary={text.title} />
            </ListItem>
          ))}
        </List>

        <Divider />

        <ListItem
          button
          key="help"
          underline="none"
          component={LinkA}
          className={classes.listItem}
          target="_blank"
          href="https://docs.globalconsent.com"
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={t("help.title")} />
        </ListItem>
      </Drawer>
    </nav>
  );
}
