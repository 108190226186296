import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    "&::before": {
      content: '"✓ "'
    }
  }
}));

export default function FeatureList({ children }) {
  const classes = useStyles();
  return <li className={classes.root}>{children}</li>;
}
