import React from "react";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
// import { useTranslation } from 'react-i18next';
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GlobalConsentIcon from "../icons/GlobalConsent";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    "@media print": {
      display: "none"
    },
    color: theme.palette.text.default,
    background: theme.palette.background.default
  }
}));

export default function MainAppBar({ onClose }) {
  const classes = useStyles();
  //   const { t } = useTranslation();
  const notifications = 0;
  // var user = firebase.auth().currentUser;

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <GlobalConsentIcon height={20} variant="medical" to="/" />
        <div style={{ flexGrow: 1 }} />

        <IconButton
          aria-label={`show ${notifications} new notifications`}
          color="inherit"
        >
          <Badge badgeContent={notifications} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <IconButton
          component={Link}
          to="/settings"
          aria-label="show settings"
          color="inherit"
        >
          <SettingsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

/*
        {user.photoURL && (
          <Avatar
            alt="Profile"
            variant="rounded"
            component={Link}
            to="/settings"
            style={{ marginLeft: '10px' }}
            src={user.photoURL}
          />
        )}
        */
