import React from "react";
import firebase from "firebase/app";
import { makeStyles, lighten } from "@material-ui/core/styles";
import { useDownloadURL } from "react-firebase-hooks/storage";
import UserContext from "../../../context/UserContext";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.dark, 0.6),
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    height: "100%",
    minHeight: "100px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    cursor: "pointer",
    transition: "1s all ease",
    "&:hover": {
      background: theme.palette.background.dark
    },
    "&:focus": {
      background: theme.palette.background.dark,
      outline: "1px dotted #000"
    }
  },
  inputfile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    "&:focus": {
      background: theme.palette.background.dark,
      outline: "1px dotted #000"
    }
  }
}));


export default function UserCard() {
  const user = React.useContext(UserContext);
  const classes = useStyles();
  const { uid } = firebase.auth().currentUser;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value] = useDownloadURL(
    firebase.storage().ref(`users/${uid}/profile/thumb_profile`));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user.profile)
    return (
      <>
        <Avatar
          className={classes.avatar}
          aria-controls="simple-menu"
          aria-haspopup="true"
          src={value}
          onClick={handleClick}
        >
          {firebase
            .auth()
            .currentUser.email.slice(0, 1)
            .toUpperCase()}
        </Avatar>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem component={Link} to="/settings/">
            Profile
          </MenuItem>
          <MenuItem component={Link} to="/settings/account">
            My account
          </MenuItem>
          <MenuItem
            onClick={() => {
              firebase.analytics().logEvent("signout_successful");
              firebase.auth().signOut();
            }}
          >
            Sign Out
          </MenuItem>
        </Menu>
      </>
    );
}
