import {
  createMuiTheme,
  responsiveFontSizes,
  darken
} from "@material-ui/core/styles";
/**
 * The GC medical theme
 * 388CD5 blue
 * e6332a red
 * 58b031 dental green
 * f4a7be pink
 * de9503 orange
 * @see typekit "https://use.typekit.net/ksp7dai.css"
 */
export default responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: "acumin-pro, sans-serif",
      // Used for page titles
      h1: {
        fontSize: "2rem",
        fontWeight: 600,
        fontFamily: "poppins, sans-serif"
      },
      // Used for Card titles
      h2: {
        fontSize: "1.3rem",
        fontWeight: 600,
        fontFamily: "poppins, sans-serif"
      },
      // Used for directions
      h3: {
        fontSize: "1.3rem",
        fontWeight: 600
      },
      h4: {
        fontSize: "1.2rem",
        fontWeight: 600
      },
      // Used for Breadcrumbs
      h5: {
        fontSize: ".9rem",
        textTransform: "uppercase"
      },
      // Used for Dialog titles
      h6: {
        fontSize: "1.3rem",
        fontWeight: 600,
        fontFamily: "poppins, sans-serif"
      },
      // Used for Global Consent Icon
      subtitle1: {
        fontFamily: "poppins, sans-serif",
        fontSize: "1.3rem",
        fontWeight: 600,
        opacity: 0.9
      },
      subtitle2: {
        fontSize: "1.6rem",
        fontWeight: 600,
        opacity: 0.86
      },
      body1: {
        fontWeight: 500,
        fontSize: "1.2rem"
      },
      body2: {
        fontSize: "1.09rem"
      },
      button: {
        textTransform: "normal",
        fontSize: "1.09rem",
        fontWeight: 400
      },
      caption: {
        fontSize: "1.09rem",
        fontWeight: 400
      },
      overline: {}
    },
    shape: {
      borderRadius: 8
    },
    palette: {
      text: {
        primary: "#40516f" //"rgba(12,21,40,0.7)",
      },
      primary: {
        main: "#388CD5",
        accent: "#28a745",
        right: "#d5383f",
        left: "#38b9d5"
      },
      secondary: {
        main: "#28a745"
      },
      error: {
        main: "#f8d7da",
        contrastText: "#721c24"
      },
      success: {
        main: "#d4edda"
      },
      info: {
        main: darken("#388CD5", 0.2),
        contrastText: "#FFF"
      },
      background: {
        default: "#f3f4f6",
        light: "#ffffff",
        mid: "#dbe7f3",
        dark: "#0d1f32"
      }
    },
    shadows: [
      "none",
      "0px 2px 1px -1px rgba(1,4,12,0.15),0px 1px 1px 0px rgba(1,4,12,0.1),0px 1px 3px 0px rgba(1,4,12,0.08)",
      "0px 3px 1px -2px rgba(1,4,12,0.15),0px 2px 2px 0px rgba(1,4,12,0.1),0px 1px 5px 0px rgba(1,4,12,0.08)",
      "0px 3px 3px -2px rgba(1,4,12,0.15),0px 3px 4px 0px rgba(1,4,12,0.1),0px 1px 8px 0px rgba(1,4,12,0.08)",
      "0px 2px 4px -1px rgba(1,4,12,0.15),0px 4px 5px 0px rgba(1,4,12,0.1),0px 1px 10px 0px rgba(1,4,12,0.08)",
      "0px 3px 5px -1px rgba(1,4,12,0.15),0px 5px 8px 0px rgba(1,4,12,0.1),0px 1px 14px 0px rgba(1,4,12,0.08)",
      "0px 3px 5px -1px rgba(1,4,12,0.15),0px 6px 10px 0px rgba(1,4,12,0.1),0px 1px 18px 0px rgba(1,4,12,0.08)",
      "0px 4px 5px -2px rgba(1,4,12,0.15),0px 7px 10px 1px rgba(1,4,12,0.1),0px 2px 16px 1px rgba(1,4,12,0.08)",
      "0px 5px 5px -3px rgba(1,4,12,0.15),0px 8px 10px 1px rgba(1,4,12,0.1),0px 3px 14px 2px rgba(1,4,12,0.08)",
      "0px 5px 6px -3px rgba(1,4,12,0.15),0px 9px 12px 1px rgba(1,4,12,0.1),0px 3px 16px 2px rgba(1,4,12,0.08)",
      "0px 6px 6px -3px rgba(1,4,12,0.15),0px 10px 14px 1px rgba(1,4,12,0.1),0px 4px 18px 3px rgba(1,4,12,0.08)",
      "0px 6px 7px -4px rgba(1,4,12,0.15),0px 11px 15px 1px rgba(1,4,12,0.1),0px 4px 20px 3px rgba(1,4,12,0.08)",
      "0px 7px 8px -4px rgba(1,4,12,0.15),0px 12px 17px 2px rgba(1,4,12,0.1),0px 5px 22px 4px rgba(1,4,12,0.08)",
      "0px 7px 8px -4px rgba(1,4,12,0.15),0px 13px 19px 2px rgba(1,4,12,0.1),0px 5px 24px 4px rgba(1,4,12,0.08)",
      "0px 7px 9px -4px rgba(1,4,12,0.15),0px 14px 21px 2px rgba(1,4,12,0.1),0px 5px 26px 4px rgba(1,4,12,0.08)",
      "0px 8px 9px -5px rgba(1,4,12,0.15),0px 15px 22px 2px rgba(1,4,12,0.1),0px 6px 28px 5px rgba(1,4,12,0.08)",
      "0px 8px 10px -5px rgba(1,4,12,0.15),0px 16px 24px 2px rgba(1,4,12,0.1),0px 6px 30px 5px rgba(1,4,12,0.08)",
      "0px 8px 11px -5px rgba(1,4,12,0.15),0px 17px 26px 2px rgba(1,4,12,0.1),0px 6px 32px 5px rgba(1,4,12,0.08)",
      "0px 9px 11px -5px rgba(1,4,12,0.15),0px 18px 28px 2px rgba(1,4,12,0.1),0px 7px 34px 6px rgba(1,4,12,0.08)",
      "0px 9px 12px -6px rgba(1,4,12,0.15),0px 19px 29px 2px rgba(1,4,12,0.1),0px 7px 36px 6px rgba(1,4,12,0.08)",
      "0px 10px 13px -6px rgba(1,4,12,0.15),0px 20px 31px 3px rgba(1,4,12,0.1),0px 8px 38px 7px rgba(1,4,12,0.08)",
      "0px 10px 13px -6px rgba(1,4,12,0.15),0px 21px 33px 3px rgba(1,4,12,0.1),0px 8px 40px 7px rgba(1,4,12,0.08)",
      "0px 10px 14px -6px rgba(1,4,12,0.15),0px 22px 35px 3px rgba(1,4,12,0.1),0px 8px 42px 7px rgba(1,4,12,0.08)",
      "0px 11px 14px -7px rgba(1,4,12,0.15),0px 23px 36px 3px rgba(1,4,12,0.1),0px 9px 44px 8px rgba(1,4,12,0.08)",
      "0px 11px 15px -7px rgba(1,4,12,0.15),0px 24px 38px 3px rgba(1,4,12,0.1),0px 9px 46px 8px rgba(1,4,12,0.08)"
    ]
  })
);

// font-weight: 700;
// font-style: normal;

// Acumin Pro Extra Light
// font-family: acumin-pro, sans-serif;
// font-weight: 200;
// font-style: normal;

// Acumin Pro Light
// font-family: acumin-pro, sans-serif;
// font-weight: 300;
// font-style: normal;

// Acumin Pro Regular
// font-family: acumin-pro, sans-serif;
// font-weight: 400;
// font-style: normal;

// Acumin Pro Semibold
// font-family: acumin-pro, sans-serif;
// font-weight: 600;
// font-style: normal;
