// framer motion effects
export const variants = {
  visible: {
    y: 0,
    opacity: 1
  },
  hidden: {
    opacity: 0
  },
  hiddenUp: {
    y: -50,
    opacity: 0
  },
  hiddenDown: {
    y: 50,
    opacity: 0
  }
};
