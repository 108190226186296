import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    listStyle: "none",
    fontSize: "1.02rem",
    fontWeight: 500,
    textAlign: "left",
    color: "#FFF"
  }
}));

export default function FeatureList({ children }) {
  const classes = useStyles();
  return <ul className={classes.root}>{children}</ul>;
}
