// This import loads the firebase namespace.
import firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

firebase.initializeApp({
  apiKey: "AIzaSyAYC30xP8f7LuGATyIaC9KpwlhOZ7BnRAU",
  authDomain: "globalconsentapp.firebaseapp.com",
  databaseURL: "https://globalconsentapp.firebaseio.com",
  projectId: "globalconsentapp",
  storageBucket: "globalconsentapp.appspot.com",
  messagingSenderId: "805534813648",
  appId: "1:805534813648:web:edfb40a2d9a2ca03cabdd5",
  measurementId: "G-6DZ2C36QQW"
});

firebase.analytics().logEvent("dashboard_app_loaded");
