import React, { lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// lazy load the routes to split up the code package
const SignIn = lazy(() => import("../components/auth/SignIn"));
const ScanCode = lazy(() => import("../components/auth/ScanCode"));
const SignUp = lazy(() => import("../components/auth/SignUp"));
const Forgot = lazy(() => import("../components/auth/Forgot"));

/**
 * Router for unauthenticated users. Sign up and login form here.
 * @param {*} props
 */
function PublicRouter(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/scan" component={ScanCode} />
        <Route exact path="/forgot" component={Forgot} />
        <Route component={SignIn} />
      </Switch>
    </BrowserRouter>
  );
}

export default PublicRouter;
