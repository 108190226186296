import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

// interfaces
import MainView from "../components/core/interfaces/MainView";
import DrawerMenu from "../components/core/interfaces/AdminDrawer";
import MobileTabs from "../components/core/interfaces/AdminMobileTabs";
import AppBar from "../components/core/interfaces/AdminAppBar";

// lazy load the routes. this splits up the code package
const CreateViewer = lazy(() =>
  import("../components/createViewer/CreateViewer")
);
// const Error404 = lazy(() => import("../components/errors/Error404"));
const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const Directory = lazy(() => import("../components/directory/Directory"));

const CreateCourse = lazy(() =>
  import("../components/createCourse/CreateCourse")
);
const Profile = lazy(() => import("../components/profile/Profile.js"));
const Browse = lazy(() => import("../components/browse/Browse.js"));
const Module = lazy(() => import("../components/module/Module"));
const Settings = lazy(() => import("../components/settings/Settings"));

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  }
}));

/**
 * Client side router for logged in users. Also has navbar here.
 * @param {*} props
 */
function AuthenticatedRouter(props) {
  const classes = useStyles();
  const [createViewerOpen, setCreateViewerOpen] = React.useState(false);
  const [createCourseOpen, setCreateCourseOpen] = React.useState(false);
  const [createCourseId, setCreateCourseId] = React.useState(null);

  /** @todo. this hack is confusing.
   * replace handle open with two functions
   */
  function handleOpen(target) {
    if (target === "create-viewer") {
      setCreateViewerOpen(true);
    } else {
      setCreateCourseId(target);
      setCreateCourseOpen(true);
    }
  }

  return (
    <div className={classes.root}>
      <AppBar onOpenDialog={handleOpen} />

      <Hidden mdUp implementation="css">
        <MobileTabs />
      </Hidden>

      <Hidden smDown implementation="css">
        <DrawerMenu onOpenDialog={handleOpen} />
      </Hidden>

      <MainView>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path="/browse" component={Browse} />

            <Route
              path="/module/:userId/:courseId/:moduleId"
              component={Module}
            />
            <Route exact path="/directory/:id">
              <Profile onOpenDialog={handleOpen} />
            </Route>
            <Route exact path="/directory">
              <Directory onOpenDialog={handleOpen} />
            </Route>
            <Route path="/settings">
              <Settings
                onClose={() => {
                  setCreateViewerOpen(false);
                  setCreateCourseOpen(false);
                }}
              />
            </Route>
            <Route>
              <Dashboard onOpenDialog={handleOpen} />
            </Route>
          </Switch>
        </Suspense>
      </MainView>

      <CreateViewer
        isOpen={createViewerOpen}
        onClose={() => {
          setCreateViewerOpen(false);
        }}
      />
      <CreateCourse
        isOpen={createCourseOpen ? true : false}
        id={createCourseId}
        onClose={() => {
          setCreateCourseOpen(false);
        }}
      />
    </div>
  );
}

export default AuthenticatedRouter;
