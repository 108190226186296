import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
// import GlobalConsentIcon from '../icons/GlobalConsent';
// import { useSpring, config, animated } from 'react-spring';
// import posed, { PoseGroup } from 'react-pose';
import { motion } from "framer-motion";
import { variants } from "../../utils/framer";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    zIndex: "1000",
    background: theme.palette.background.default
  },
  progress: {
    margin: theme.spacing(3),
    opacity: 0.8
  }
}));

function LoadingView({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.intro}>
      <>
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <CircularProgress className={classes.progress} />
        </motion.div>
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <Typography>{message}</Typography>
        </motion.div>
      </>
    </div>
  );
}

export default LoadingView;
