export function parseVersion() {
  const metas = document.getElementsByTagName("meta");
  for (const m of metas) {
    if (m.getAttribute("name") === "version") {
      const version = m.getAttribute("content");
      return version;
    }
  }
}

console.log(`Welcome to Global Consent, version ${parseVersion()}`);
